function ListItems (props) {
    const items1 = Object.values(props.listItems)
    let itemsList = items1.map((item, index) => {
        return(
            <li
                key={index.toString()}
                className='descr__item'
                >
                {item.text}
            </li>
        )
    })
    return(
        <ul className='descr__items'>
            {itemsList}
        </ul>
    )
}

export default ListItems