import { useContext } from "react"
import "./LanguageSwitch.sass"
import { Context } from '../context/Context';


function LanguageSwitch() {

    const {isRu, changeLang} = useContext(Context)

    return(
            <div   
                onClick={changeLang}
                className="ruEn"
            >    
                <div className={isRu? 'ruEn__item blue' : 'ruEn__item'}>
                    rus
                </div>
                <div className={isRu? 'ruEn__item' : 'ruEn__item blue'}>
                    eng
                </div>
            </div>
    )
    
}

export default LanguageSwitch