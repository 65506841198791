import './FirstSlide.sass'
// import castle from '../images/Castle.png'
import castle from '../images/Castle4.png'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch'
import ButtonDown from '../ButtonDown/ButtonDown'
import Metaballs from '../Metaballs/Metaballs'
import { useContext, useEffect, useState} from "react"
import { Context } from "../context/Context"


function FirstSlide(props) {
    const [textTepmlate, setTextTemplate] = useState('')
    const {data, isRu, index, openPopupTwo, scrollHandler, setInputValue, isMobile} = useContext(Context)
    const [content, setContent] = useState()
    
    function fillText(el) {
        let text = el['firstslide_text']
            if (text) 
                {let textTemp = text.map((textData, num) => {
                    return(
                        <p key={num} className="firstSlide__text">
                            {textData.text}
                        </p>
                    )
                })
                setTextTemplate(textTemp)
                }
            else {
                setTextTemplate(
                    <>
                        <p className="firstSlide__text">
                            Студия GameFirst приветствует тебя! Мы, команда независимых разработчиков, уже больше десяти лет занимаемся любимым делом — создаем игры для мобильных и ПК.
                        </p>
                        <p className="firstSlide__text">
                            Хочешь воплощать новые идеи в игровой индустрии? Тогда го к нам в команду!
                        </p>
                    </>
                )
            }
        }
        useEffect(() => {
            
            let tempData = data?
                            isRu? data.firstslide
                                : data.firstslide_eng
                            : ''
            setContent(tempData)
            fillText(tempData)
        }, [data, isRu])

        const publGame = () => {
            scrollHandler('sixth', 5)
            setInputValue(content? content.firstslide_btn2 : 'Издать игру')
        }

        return (
            <div 
                id="first"
                className = "firstSlide"
            > 
                {!isMobile?
                    <Metaballs
                        index = {index}
                        count = {0}
                    /> : ''
                }
                <img className="firstSlide__castle" src={content? content.firstslide_foto : castle } alt="castleImage"/>
                <div className="firstSlide__textContaiter">
                    <h1 className="firstSlide__title">
                        {content? content.firstslide_title : 'Здесь рождаются игры'
                        }
                        </h1>
                    {textTepmlate}
                    <div className="firstSlide__buttons">
                        <button 
                            onClick = {openPopupTwo}
                            className="firstSlide__button
                        firstSlide__button_blue"
                        >
                            {content? content.firstslide_btn1 : 'Хочу в команду'}
                        </button>
                        <button 
                            onClick={() => publGame()}
                            className="firstSlide__button firstSlide__button_white">
                                {content? content.firstslide_btn2 : 'Издать игру'}
                    </button>
                </div>
            </div>
            <ButtonDown 
                swiper = { props.swiper }
            />
            <LanguageSwitch 
            />
        </div>
        );
    }
  
export default FirstSlide;