import { useEffect, useState } from 'react';
import axios from 'axios'
import './components/styles/commonStyles.sass'
import './components/styles/variables.sass'
import './components/styles/fonts.sass'
import Header from './components/Header/Header'
import Slider from './components/Slider/Slider'
import PopupOne from './components/PopupOne/PopupOne'
import PopupTwo from './components/PopupTwo/PopupTwo'
import BurgerMenu from './components/BurgerMenu/BurgerMenu';
import { Context } from './components/context/Context';
import MobileTemplate from './components/MobileTemplate/MobileTemplate';
import Metaballs from './components/Metaballs/Metaballs';
import slideNumber from './components/SlideNumber/SldieNumber';


function App() {
  const [isOpenOne, setIsOpenOne] = useState(false)
  const [isOpenTwo, setIsOpenTwo] = useState(false)
  const [job, setJob] = useState(null)
  const scrollHandler = (elId, view) => {
    if (isMobile) {
      const element = document.getElementById(elId)
      const topPos = element.getBoundingClientRect().top + window.pageYOffset
      element.scrollIntoView({behavior: "smooth"})
    }
    else {
      swiper.slideTo(view)
    }
    if (!hide) {
      hideHandler()
    }
  }

  //sixthSlide form
  const [isSent, setIsSent] = useState(false)
  const handleIsSent = (value) => {
      setIsSent(value)
  }
  const [err, setErr] = useState(false)
  const handleErr = (value) => {
      setErr(value)
  }

  //popup
  const openPopupOne = (job) => {
    setIsOpenOne(true)
  }

  const openPopupTwo = () => {
    setIsOpenOne(false)
    setIsOpenTwo(true)
  }

  const closePopup = () => {
    setIsOpenOne(false)
    setIsOpenTwo(false)
  }

  const [swiper, setSwiper] = useState(0)
  const initSwiper = (el) => {
    setSwiper(el)
  }

  const [isRu, setIsRu] = useState(true)
  const changeLang = () => {
    setIsRu((isRu) => !isRu)
  }

  const [coinNum, setCoinNum] = useState('1')

  const [hide, setHide] = useState(true)
  const hideHandler = () => {
    setHide(hide => !hide)
  }
  const [inputValue, setInputValue] = useState('')
  const [index, setIndex] = useState(0)
  const [indexHorisontal, setIndexHorisontal] = useState(0)
  const [shakingForm, setShakingForm] = useState()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992)
  const [discordUrl, setDiscordUrl]= useState("https://www.discord.gg/cqDpR7a")

  //post
  const dataUrl = "https://admin.gamefirst.ru/api/"
  const [data, setData] = useState()
  useEffect(() => {
      axios.get(dataUrl, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(function (response) {
        setData(response.data)
        console.log(response.data)
        slideNumber()
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
      })
  }, [])

  const LightenDarkenColor = (col, amt) => {
    let usePound = false;
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }
    let num = parseInt(col,16);
    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    let g = (num & 0x0000FF) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  }

  const isHex = (col) => {
    let regEx = /^#(?:[0-9a-fA-F]{3}){1,2}$/
    return regEx.test(col)
  }

  const [slideNum, setSlideNum] = useState(0)
  const [numberForMetaballs, setNumberForMetaballs] = useState(0)

  useEffect(() => {
    setNumberForMetaballs(slideNum)
  }, [slideNum])

  // document.querySelector('meta[name="description"]').setAttribute("content", 'lala');
  // console.log(document.getElementsByName('description'))

  useEffect(() => {
    let doc = document.documentElement
    if(data && isHex(data.secondslides.secondslide[indexHorisontal].secondslide_color)) {
      doc.style.setProperty('--primary', data.secondslides.secondslide[indexHorisontal].secondslide_color)
      doc.style.setProperty('--secondary', LightenDarkenColor(data.secondslides.secondslide[indexHorisontal].secondslide_color, -20))
    }
    else {
      doc.style.setProperty('--primary', '#98ADFF')
      doc.style.setProperty('--secondary', LightenDarkenColor('#98ADFF', -20))
    }
  }, [data, indexHorisontal])


  useEffect(() => {
    handleIsSent(false)
    handleErr(false)
  }, [index, slideNum])

  
  return (
    <div 
      onScroll={() => setSlideNum(slideNumber())}
      className="App">
      <Context.Provider value={{
        isRu,
        err,
        job,
        hide,
        data,
        index,
        isSent,
        coinNum,
        slideNum,
        isMobile,
        isOpenOne,
        isOpenTwo,
        discordUrl,
        inputValue,
        shakingForm,
        indexHorisontal,
        isHex,
        setJob,
        handleErr,
        setCoinNum,
        closePopup,
        changeLang,
        hideHandler,
        handleIsSent,
        openPopupOne,
        openPopupTwo,
        setDiscordUrl,
        scrollHandler,
        setInputValue,
        setShakingForm,
        setIndexHorisontal
      }}>
        <BurgerMenu 
          swiper = {swiper}
        />
        <PopupOne 
          job={job}
          isOpen = {isOpenOne}
          />
        <PopupTwo 
          isOpen = {isOpenTwo}
        />
        {isMobile?
          <Metaballs
            isMobile = {isMobile}
            index = {index}
            count = {numberForMetaballs}
          /> : ''
        }
        <Header 
          swiper = {swiper}
        />
        {!isMobile?
            <Slider 
              swiper = {swiper}
              initSwiper = {initSwiper}
              setIndex = {setIndex}
            /> 
            : 
            <MobileTemplate 
              swiper = {swiper}
              initSwiper = {initSwiper}
              setIndex = {setIndex}
            />
        }
      </Context.Provider>
    </div>
  );
}

export default App;
