import { useContext } from "react"
import { Context } from "../context/Context"
import ListItems from "./ListItems"

function JobsItem(props) {
    const {openPopupOne, openPopupTwo, closePopup, isOpenOne, job, setJob} = useContext(Context)
    const chooseJob = (num) => {
        setJob(num)
        openPopupOne()
        if (props.place === 'onSlide' && isOpenOne && props.jobNum === job) {
            closePopup()
        } 
    }

    return(
        <>
            <li 
                id={props.last? 'last' : ''}
                onClick = {() => chooseJob(props.jobNum)}
                className = {isOpenOne && props.jobNum === job? "jobs__item chosen" : "jobs__item"}>
                    {props.mainTitle}
                <span> + </span>
            </li>
            <div 
                className={isOpenOne && props.jobNum === job? 'item__descr' : 'item__descr_hide'}
            >
                <h3 className="descr__title">
                    {props.titleDuties}
                </h3>
                <ListItems 
                   listItems = {props.listItems1}
                />
                <h3 className="descr__title">
                    {props.titleEpxpectations}
                </h3>
                <ListItems 
                   listItems = {props.listItems2}
                />
                <button 
                    onClick={openPopupTwo}
                    className="descr__button">
                    Отправить резюме
                </button>
            </div>
        </>    
    )
}

export default JobsItem