import './ButtonDown.sass'
import arrowDown from '../images/arrowDown.svg'

function ButtonDown(props) {
    return(
        <button
            className="buttonDown"
            onClick={() => props.swiper.slideTo(1)}
        >
            <img 
                src={arrowDown}
                alt={'downArrow'}
                className="buttonDown__arrow">
            </img>
        </button>
    )
}

export default ButtonDown