import "./BurgerMenu.sass"
import mainLogo from '../images/newLogo.svg'
import discordLogoWhite from '../images/discordButton.svg'
import closeCross from "../images/x.svg"
import { useContext, useState, useEffect } from "react"
import { Context } from "../context/Context"


function BurgerMenu(props) {
    const {hide, hideHandler, changeLang, isRu, scrollHandler, discordUrl, data, coinNum} = useContext(Context)
    const [content, setContent] = useState()
    const [contacts, setContacts] = useState()
    const langHandler = () => {
        changeLang()
        hideHandler()
    }

    useEffect(() => {
        let tempData = data?
                        isRu? data.menu
                            : data.menu_eng
                        : ''
        setContent(tempData)
        let contactsData = data?
                        isRu? data.header_contacts
                            : data.header_contacts_eng
                        : ''
        setContacts(contactsData)
    }, [data, isRu])

    return(
        <>
            <div className={hide? 'burgerMenu__hide' : 'burgerMenu noSwipe'}
            >
                <div className="burgerMenu__header">
                        <img src={mainLogo} 
                            className="burgerMenu__logo" 
                            alt="mainLogo"
                            onClick={() => scrollHandler('first', 0)}
                        />
                    <div 
                        className="header__close"
                        onClick = {hideHandler}
                    >
                        <img 
                            src={closeCross}
                            alt="close"> 
                        </img>
                    </div>
                </div>
                <span 
                    onClick={langHandler}
                    className="burgerMenu__lang">
                        {
                            isRu? 'English version →' : 'Русская версия →'
                        }
                </span>
                <nav >
                    <ul className="burgerMenu__nav">
                        <li 
                            className="nav__item"
                            onClick={() => scrollHandler('second', 1)}
                        >
                            {content? content[0].title : 'Проекты'}
                        </li>
                        <li 
                            onClick={() => scrollHandler('third', 2)}
                            className="nav__item">
                            {content? content[1].title : 'О компании'}
                        </li>
                        <li 
                            onClick={() => scrollHandler('fourth', 3)}
                            className="nav__item red">
                            {content? content[2].title : 'Вакансии'}
                            <span className="coin">
                                {coinNum}
                            </span>
                        </li>
                        <li 
                            onClick={() => scrollHandler('fifth', 4)}
                            className="nav__item"
                        >
                            {content? content[3].title : 'Паблишинг'}
                        </li>
                        <li
                            onClick = {() => scrollHandler('sixth', 5)}
                            className="nav__item">
                            {contacts? contacts : 'Контакты'}
                        </li>
                    </ul>
                </nav>
                <div className="burgerMenu__buttons">
                    <button 
                        onClick={() => scrollHandler('sixth', 5)}
                        className="button__feedback">
                        {isRu? 'Обратная связь' : 'Feedback'}
                    </button>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={discordUrl}
                    >
                        <button         className="button__discord">
                            <img 
                                alt={'discord'}
                                src={discordLogoWhite} />
                        </button>
                    </a>
                    
                </div>
            </div>
        </>
    )
}

export default BurgerMenu