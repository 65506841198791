import "./SixthSlide.sass"
import vkLogo from "../images/vkLogo.svg"
import fbLogo from "../images/fbLogo.svg"
import discordLogo from '../images/discordLogo.svg'
// import girl from '../images/girl.png'
import girl from '../images/Girl4.png'
import Metaballs from '../Metaballs/Metaballs'
import { useContext, useState, useEffect } from "react"
import { Context } from "../context/Context"
import FeedBackForm from "./FeedBackForm/FeedBackForm"
import SendResult from "./SendResult/SendResult"
import MakeLinksMove from '../HoverButton/HoverButtonSixth'

function SixthSlide(props) {
    const vkUrl = "https://vk.com/gfplay"
    const fbUrl = "https://www.facebook.com/gamefirst.prod"
    const discordUrl = "https://www.discord.gg/cqDpR7a"
    const [yourEmail, setYourEmail] = useState('')
    const handleEmail = (value) => {
        setYourEmail(value)
    }
 
    const {isRu, data, index, isMobile} = useContext(Context)
    const [content, setContent] = useState()
    useEffect(() => {
        let tempData = data?
                        isRu? data.sixthslide
                            : data.sixthslide_eng
                        : ''
        setContent(tempData)
    }, [data, isRu]) 

    useEffect(() => {
        if(index === 5) {
            setTimeout(() => {
                MakeLinksMove(document.querySelectorAll('.links__item'))
            }, 1000);
        }
    })
    
    return(
        <div 
            id="sixth"
            className="sixthSlide">
            {!isMobile?
                <Metaballs
                    index = {index}
                    count = {5}
                /> : ''
            }
            <div className="sixthSlide__leftSide">
                <h2 className="leftSide__title">
                    {content? content.leftside_header : 'title'}
                </h2>
                <a  
                    href="mailto:info@gamefirst.pro"
                    className="leftSide__mail">
                    {content? content.leftside_mail : 'info@gamefirst.pro'}
                </a>

                <ul className="leftSide__links">
                    <li className="links__item">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href = {vkUrl}
                        >
                            <div className="item__image">
                                <img src={vkLogo} alt={'vk'}></img>
                            </div>
                            <div className="item__text">
                                vk.com/
                                <span>
                                gfplay
                                </span>
                            </div>
                        </a>
                    </li>
                    <li className="links__item">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href = {fbUrl}
                        >
                            <div className="item__image">
                                <img src={fbLogo} alt={'faceBook'}></img>
                            </div>
                            <div className="item__text">
                                facebook.com/   
                                <span>
                                    gamefirst.prod
                                </span>
                            </div>
                        </a>
                    </li>
                    <li className="links__item">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href = {discordUrl}
                        >
                            <div className="item__image">
                                <img src={discordLogo} alt={'discord'}></img>
                            </div>
                            <div className="item__text">
                                discord.gg/
                                <span>
                                    cqDpR7a
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="sixthSlide__rightSide">
                <SendResult 
                    content = {content}
                    yourEmail={yourEmail}
                />
                <FeedBackForm 
                    handleEmail = {handleEmail}
                />
                <div className = "rightSide__copyright">
                    {content? content.rigthside_copyright : '© 2011–2018 GameFirst.ru'}
                </div>
            </div>
            <img 
                src={content? content.sixthslide_foto : girl}
                alt={'girl'}
                className="sixthSlide__girl">
            </img>
        </div>
    )
}

export default SixthSlide