import "./PopupTwo.sass"
import closeCross from "../images/x.svg"
import { useContext, useEffect, useState, useRef} from 'react'
import axios from 'axios'
import { Context } from "../context/Context"
import Loader from '../SixthSlide/Loader/Loader'
import PopupTwoSendResult from "./PopupTwoSendResult"

function PopupTwo(props) {
    const {closePopup} = useContext(Context)
    const [isVisible, setIsVisible] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const [sendError, setSendError] = useState(false)
    const rootEl = useRef(null);
    function handleClickOutside(e) {
        if (!rootEl.current.contains(e.target)) {
            closePopup()
        }
    }
    const {isRu, data} = useContext(Context)
    const [content, setContent] = useState()
    useEffect(() => {
        let tempData = data?
                        isRu? data.sixthslide
                            : data.sixthslide_eng
                        : ''
        setContent(tempData)
    }, [data, isRu]) 

    let dataToSend = new FormData()
    const url = "https://admin.gamefirst.ru/mail/"
    function fillData() {
        dataToSend.append('userName', document.getElementById('resumeName').value)
        dataToSend.append('email', document.getElementById('resumeEmail').value)
        dataToSend.append('theme', document.getElementById('resumeTheme').value)
        dataToSend.append('text', document.getElementById('resumeText').value)
        let file = document.querySelector('#resumeFile')
        dataToSend.append("file", file.files[0])
    }

    const handleFile = () => {
        setFileInputIsEmpty(false)
        setResumeFileName(document.getElementById('resumeFile').files[0]? 
                            document.getElementById('resumeFile').files[0].name 
                            : ''
                            )
    }  

    const submitHandler = () => {
        setSendError(false)
        setIsVisible(true)
        fillData()
        axios.post(url, dataToSend,
            {
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded' 
                },
                data: dataToSend
            })
            .then((res) => {
                setIsVisible(false)
                setIsSent(true)
                console.log(res)
            })
            .catch(function (error) {
                setIsVisible(false)
                setSendError(true)
                setIsSent(true)
                console.log(error, 'err')
            })
            .then(function () {
                // always executed
            });
    }

    const makeFormVisible = () => {
        setIsSent(false)
        setSendError(false)
    }

    const [shakingCheckBox, setShakingCheckBox] = useState()
    const [fileInputIsEmpty, setFileInputIsEmpty] = useState()
    const [resumeFileName, setResumeFileName] = useState()
    const checkRequires = () => {
        if (!document.getElementById('checkbox').checked) {
            setShakingCheckBox('shaking')
            setTimeout(() => setShakingCheckBox(' '), 1000)
        }
        if (!document.getElementById('resumeFile').value) {
            setFileInputIsEmpty(true)
        }
    }

    const checkBoxHandler = () => {
        if (document.getElementById('checkbox').checked) {
            setShakingCheckBox('')
        }
    }

    useEffect(() => {
        makeFormVisible()
        setShakingCheckBox('')
        setFileInputIsEmpty(false)
    }, [props.isOpen])

    return(
        <div 
            className={props.isOpen? "popup-two" : "disabled"}
            onClick = {handleClickOutside}
        >
            <div 
                ref={rootEl}
                className={'popup__container'} 
            >
                <div 
                    className="popup__close"
                    onClick = {closePopup}
                >
                    <img 
                        src={closeCross}
                        alt="close"> 
                    </img>
                </div>
                <h3 
                className={"container__title"} 
                >
                    {isSent? sendError? 
                            ''
                            :''
                            :(isRu? 'Отправить резюме' : 'Submit your CV')}
                   
                </h3>
                <PopupTwoSendResult 
                    isSent = {isSent}
                    sendError = {sendError}
                    makeFormVisible = {makeFormVisible}
                />
                <form 
                    onSubmit = {(event) => {
                        submitHandler(event); 
                        event.preventDefault()
                    }}
                    className={isSent? "container__form_hidden" : "container__form"} 
                >
                    <input 
                        id='resumeName'
                        required
                        placeholder={content? content.form_name : 'name'}
                        type='text'
                        className="form__input"
                    ></input>
                    <input 
                        id="resumeEmail"
                        required
                        placeholder={content? content.form_mail : 'e-mail'}
                        type='email'
                        className="form__input"
                    ></input>
                    <input 
                        id="resumeTheme"
                        required
                        placeholder={content? content.form_theme : 'theme'}
                        type='text'
                        className="form__input short"
                    ></input>
                    <label 
                        className= {fileInputIsEmpty? 'form__input short file empty'     : "form__input short file"}
                        >
                        <input 
                            onChange={handleFile}
                            id='resumeFile'
                            type='file'
                            className='fileInput'
                            // required
                        />
                        {resumeFileName? resumeFileName : (isRu? "Загрузить резюме" : 'Upload file')}
                        
                    </label>
                    <textarea
                        id='resumeText'
                        required
                        placeholder={content? content.form_text : 'text'}
                        type='text'
                        className="form__input comment"
                    >
                    </textarea>
                    <label 
                        className={"form__input checkbox-label checkbox " + shakingCheckBox}
                    >
                        <input
                            id='checkbox'
                            required
                            type='checkbox'
                            onChange = {checkBoxHandler}
                        />
                        <span className={"checkmark"}  ></span>
                            {content? content.form_checkmark : 'policy'}
                    </label>
                    <input
                        onClick = {checkRequires}
                        type="submit"
                        className = "container__button button"
                        value={content? content.form_button : 'submit'}
                    >
                    </input>
                </form>
                <Loader
                    onPopup = {true}
                    isVisible = {isVisible}
                />
                
            </div>
        </div>
    )
}

export default PopupTwo

