import './FeedBackForm.sass'
import axios from 'axios'
import { useContext, useState, useEffect } from "react"
import { Context } from "../../context/Context"
import Loader from "../Loader/Loader"

function FeedBackForm(props) {
    const {inputValue, isSent, err, handleIsSent, handleErr} = useContext(Context)
    //валидность форм
    const emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    const [isEmailValid, setEmailIsValid] = useState(true)
    const checkEmail = (e) => {
        if (e.target.value.match(emailRegEx) === null && isEmailValid) {
            setEmailIsValid(false)
        }
        else if (e.target.value.match(emailRegEx) != null && !isEmailValid) 
            setEmailIsValid(true)
            props.handleEmail(e.target.value)
    }
    const textRegEx = /^\D+$/
    const [isNameValid, setIsNameValid] = useState(true)
    const checkName = (e) => {
        if (e.target.value.match(textRegEx) === null && isNameValid) {
            setIsNameValid(false)
        }
        else if (e.target.value.match(textRegEx) != null && !isNameValid) 
            setIsNameValid(true)

    }
    const [isThemeValid, setisThemeValid] = useState(true)
    const checkTheme = (e) => {
        if (e.target.value.length >= 3) {
            setisThemeValid(true)
        }
        else setisThemeValid(false)
    }
    const [isTextValid, setIsTextValid] = useState(true)
    const checkText = (e) => {
        if (e.target.value.length >= 3) {
            setIsTextValid(true)
        }
        else setIsTextValid(false)
    }
    const [isLoading, setIsLoading] = useState(false)
    const [shakingCheckBox, setShakingCheckBox] = useState()
    const handleCheckbox = () => {
        if (!document.getElementById('feedbackCheckbox').checked) {
            setShakingCheckBox('shaking')
            setTimeout(() => setShakingCheckBox(' '), 1000)
        }
    }
    const checkBoxHandler = () => {
        if (document.getElementById('feedbackCheckbox').checked) {
            setShakingCheckBox('')
        }
    }
    
    //POST
    let feedBackData = new FormData()
    const url = "https://admin.gamefirst.ru/mail/"
    function fillData() {
        feedBackData.append('userName',document.getElementById('nameSix').value)
        feedBackData.append('email',document.getElementById('emailSix').value)
        feedBackData.append('theme',document.getElementById('themeSix').value)
        feedBackData.append('text',document.getElementById('textSix').value)
    }

    const submitHandler = (event) => {
        setIsLoading(true)
        event.preventDefault()
        fillData()
        axios.post(url, feedBackData,
            {
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded' 
                },
                data: feedBackData
            })
        .then(function (response) {
            console.log(response);
            setIsLoading(false)
            handleIsSent(true)
            handleErr(false)
        })
        .catch(function (error) {
            setIsLoading(false)
            handleIsSent(false)
            handleErr(true)
            console.log(error);
        })
        .then(function () {
            // always executed
        });
    }

    //data
    const {isRu, data, shakingForm} = useContext(Context)
    const [content, setContent] = useState()
    useEffect(() => {
        let tempData = data?
                        isRu? data.sixthslide
                            : data.sixthslide_eng
                        : ''
        setContent(tempData)
    }, [data, isRu])
    

    return(
        <form 
            onSubmit={(event) => submitHandler(event)}
            className={isSent || err? 'rightSide__form-hide' : 'rightSide__form ' + shakingForm}
        >
            <Loader 
                isVisible = {isLoading}
            /> 
            <h3 className="form__title">
                {content? content.form_header : 'title'}
            </h3>
            <div className="form__something">
                <input 
                    id = 'nameSix'
                    onFocus={(element) => checkName(element)}
                    onChange={(element) => checkName(element)}
                    placeholder=" "
                    type="text"  
                    className={isNameValid? "form__input" : 'form__input invalid'}
                    required
                />
                <span           
                    className="floating-label"
                >
                    {content? content.form_name : 'name'}
                </span>
                <div className='invalid-text'>
                    {isRu?  'Введите имя': 'Enter your name'}
                </div>
            </div>

            <div className="form__something">
                <input 
                    id='emailSix'
                    onChange={(element) => checkEmail(element)}
                    placeholder={content && content.form_email_pre? content.form_email_pre : "для нашего ответа"} 
                    type="email"  
                    className={isEmailValid? "form__input" : 'form__input invalid'}
                    required
                />
                <span           
                    className="floating-label"
                >
                    {content? content.form_mail : 'e-mail'}
                </span>
                <div className='invalid-text'>
                    {isRu? 'Введите e-mail' : 'Enter your e-mail'}
                </div>
            </div>

            <div className="form__something">
                <input 
                    id='themeSix'
                    onChange = {(element) => checkTheme(element)}
                    placeholder={content && content.form_theme_pre? content.form_theme_pre : "чтобы письмо не потерялось"} 
                    type="text"  
                    className={isThemeValid? "form__input" : 'form__input invalid'}
                    defaultValue={inputValue}
                    required
                ></input>
                <span           
                    className="floating-label"
                >
                    {content? content.form_theme : 'theme'}
                </span>
                <div className='invalid-text'>
                    {isRu? 'Введите тему': 'Enter theme'}
                </div>
            </div>

            <div className="form__something">
                <textarea 
                    id='textSix'
                    onChange={(element) => checkText(element)}
                    placeholder=" "
                    type="text"  
                    className={isTextValid? "form__input textarea" : 'form__input textarea invalid'}
                    required
                />
                <span           
                    className="floating-label"
                >
                    {content? content.form_text : 'text'}
                </span>
                <div className='invalid-text'>
                    {isRu? 'Введите сообщение' : 'Enter your message' }
                </div>
            </div>
            <div className="form__submit">
                <label
                    className={"submit__input checkbox " + shakingCheckBox} 
                >
                    <input 
                        onChange = {checkBoxHandler}
                        id='feedbackCheckbox'
                        type="checkbox"
                        required
                    />
                    <span className={"checkmark"}></span>
                        {content? content.form_checkmark : 'policy'}
                </label>
                <button 
                    onClick = {handleCheckbox}
                    className="submit__button"
                    type="submit"
                >
                    {content? content.form_button : 'submit'}
                </button>
            </div>
        </form>
    )
}

export default FeedBackForm