import FifthSlide from "../FifthSlide/FifthSlide";
import FirstSlide from "../FirstSlide/FirstSlide";
import FourthSlide from "../ForuthSlide/FourthSlide";
import HorisontalSlider from "../HorisontalSlider/HorisontalSlider";
import SixthSlide from "../SixthSlide/SixthSlide";
import ThirdSlide from "../ThirdSlide/ThirdSlide";
import React, { useEffect, useState } from "react";


function MobileTemplate(props) {

    const [inputValue, setInputValue] = useState('')
    const publGame = () => {
        setInputValue('Издать игру')
        props.swiper.slideTo(5)
    }

    return(
        <>
            <FirstSlide 
                data = {props.data}
                publGame = {publGame}
                swiper = {props.swiper}
            />
            <HorisontalSlider />
            <ThirdSlide />
            <FourthSlide />
            <FifthSlide 
                publGame = {publGame}
            />
            <SixthSlide 
                inputValue = { inputValue }
                swiper = {props.swiper}
            />
        </>
    )
}

export default MobileTemplate