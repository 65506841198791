import './PopupTwoSendResult.sass'
import check from'../images/checkSixthSlide.svg'
import err from'../images/sixthSlideErr.svg'
import { Context } from '../context/Context';
import { useContext } from 'react'

function PopupTwoSendResult(props) {
    const { closePopup } = useContext(Context)
    return(
    <>
        <div className={props.isSent && !props.sendError ? 'popupTwoSent' : 'popupTwoSent-hide'}>
            <img 
                src={check}
                className="sent__image"
                alt="sent"
            />
            <h3
                className="sent__title"
            >
                Резюме отправлено
            </h3>
            <button
                onClick = {() => closePopup()}
                className="sent__button"
            > 
                Вернуться на сайт
            </button>
        </div>

        <div className={props.isSent && props.sendError? 'popupTwoSent_err' : 'popupTwoSent_err-hide'}>
            <img 
                src={err}
                className="sent__image"
                alt="sent"
            />
            <h3
                className="sent__title"
            >
                Ошибка отправки резюме
            </h3>
            <p
                className="sent__text"
            >
            </p>
            <button
                onClick = {() => props.makeFormVisible()}
                className="sent__button"
            > 
                Попробовать снова
            </button>
        </div>
    </>
    )
}

export default PopupTwoSendResult

