import Metaballs from "../Metaballs/Metaballs";
import "./FourthSlide.sass"
import { useContext, useEffect, useState } from "react"
import { Context } from "../context/Context"
import Jobs from './Jobs'

function FourthSlide(props) {
    const {index, data, isRu, openPopupTwo, isMobile} = useContext(Context)
    const [content, setContent] = useState()
    useEffect(() => {
        let tempData = data?
                        isRu? data.fourthslide
                            : data.fourthslide_eng
                        : ''
        setContent(tempData)
    }, [data, isRu])

    return (
        <div   
            id="fourth"
            className = "fourthSlide"
        >   
            {!isMobile?
                <Metaballs
                    index = {index}
                    count = {3}
                /> : ''
            }
            <Jobs 
                place = {'onSlide'}
            />
            <div 
                className = "fourthSlide__textContainer">
                <h2 className = "textContainer__title">
                    {content? content.textcontainer_title : 'title'
                    }
                </h2>
                <p className = "textContainer__text">
                    {content? content.textcontainer_text : 'text'
                    }
                </p>
                <button 
                    onClick={openPopupTwo}
                    className="textContainer__button">
                    {content? content.textcontainer_button : 'buttonText'
                    }
                </button>
            </div>
        </div>

    );
}
  
export default FourthSlide;