import React, { useEffect, useState } from "react";
import FirstSlide from "../FirstSlide/FirstSlide";
import ThirdSlide from "../ThirdSlide/ThirdSlide";
import FourthSlide from "../ForuthSlide/FourthSlide";
import FifthSlide from "../FifthSlide/FifthSlide";
import SixthSlide from "../SixthSlide/SixthSlide";
import HorisontalSlider from "../HorisontalSlider/HorisontalSlider";
import './Slider.sass'
import './Pagination.sass'

//swiper js
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import 'swiper/swiper.scss';
import SwiperCore, {
  Mousewheel, Keyboard, Pagination
} from 'swiper/core';
SwiperCore.use([Mousewheel, Keyboard, Pagination]);


function Slider(props) {
    return (
        <Swiper 
            speed={600}
            init={false}
            onInit={(swiper) => props.initSwiper(swiper)}
            direction={'vertical'}
            simulateTouch={false}
            noSwiping
            noSwipingClass = {'noSwipe'}
            spaceBetween={0} 
            mousewheel={{
                forceToAxis: true,
                thresholdTime: 800,
                thresholdDelta: 25
            }}
            pagination = {{
                "clickable" : true,
                bulletClass: 'bullet',
                bulletActiveClass: 'bulletActive'
            }}
            keyboard={true}
            className="slider-vertical"
            onSlideChange={(swiper) => props.setIndex(swiper.activeIndex)}
            >
            <SwiperSlide>
                <FirstSlide 
                    data = {props.data}
                    swiper = {props.swiper}
                />
            </SwiperSlide>
            <SwiperSlide>
                <HorisontalSlider />
            </SwiperSlide>
            <SwiperSlide>
                <ThirdSlide />
            </SwiperSlide>
            <SwiperSlide>
                <FourthSlide />
            </SwiperSlide>
            <SwiperSlide>
                <FifthSlide 
                />
            </SwiperSlide>
            <SwiperSlide>
                <SixthSlide 
                    swiper = {props.swiper}
                />
            </SwiperSlide>
        </Swiper>
    )
}

export default Slider