import './SecondSlide.sass'
import steam from '../images/steamLogoTransparent.svg'
import steamBlack from '../images/steamLogo.svg'
import appstore from '../images/appleLogo.svg'
import appstoreBlack from '../images/appleLogoBlack.svg'
import googleplay from '../images/gPlayLogo.svg'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../context/Context'

function SecondSlide(props) {
    const colors = ['blue', 'green', 'orange', 'blue']
    const {indexHorisontal, data} = useContext(Context)
    const [market, setMarket] = useState('default')
    const isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0
    const containsButton = (buttons, buttonName) => {
        let val = Object.values(buttons)
        let namesArr = val.map(el => {
            return el.name.toLowerCase()
        })
        return (namesArr.includes(buttonName))
    }
    useEffect(() => {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            setMarket('appstore')
        }
        else if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setMarket('googleplay')
        }
        else {
            setMarket('steam')
        }    
    }, [props.buttons])

    const [buttonsTemplate, setButtonsTemplate] = useState()
    useEffect(() => {
        const logos = {
            'steam': market === 'steam'? steam : steamBlack, 
            'appstore': market === 'appstore'? appstore : appstoreBlack, 
            'googleplay': googleplay
        }
        let slideMarket = containsButton(props.buttons, market)? market : (isMac && containsButton(props.buttons, 'appstore'))? 'appstore' : 'googleplay'
        let buttonsTemp
        if (props.buttons) {
            buttonsTemp = props.buttons.map((el, index) => {
                const main = slideMarket.toLowerCase() === el.name.toLowerCase()
                return(
                    <a 
                        href={el.link}
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                        className={
                            "secondSlide__button " + 
                            el.name.toLowerCase() + " " + 
                            colors[indexHorisontal] +  " " +
                            (main? "main" : '') }
                        >
                        <img 
                            src = {
                                logos[el.name.toLowerCase()]
                            } 
                            alt = {el.name}/>
                        {main? el.text : ''}
                    </a>
                )
            })
        }
        setButtonsTemplate(buttonsTemp)
    }, [market, indexHorisontal, props.buttons])

    const [logos, setLogos] = useState([])

    useEffect(() => {
        let logosRu = []
        if (data) {
            data.secondslides.secondslide.forEach(element => {
                logosRu.push(element.secondslide_icon)
            });
        }
        setLogos(logosRu)
    }, [data])

    return (
        <div
            id="second"
            className = "secondSlide"
        >
            <img className = "secondSlide__angel" src ={props.secondslide_foto? props.secondslide_foto : ''} alt = ""></img>
            <img className = "secondSlide__gate" src ={props.secondslide_foto_2? props.secondslide_foto_2 : ''} alt = ""></img>
            <div className = "secondSlide__appInfo">
                <div className = "appInfo__header">
                    {/* <img className = "appInfo__logo" src={props.appinfo_logo? props.appinfo_logo : ''} alt = "appLogo"/> */}
                    <img className = "appInfo__logo" src={logos[props.num]}alt = "appLogo"/>
                    <div className = "appInfo__spans">
                        <h3 className = "appInfo__title">
                            {props.appinfo_title}
                        </h3>
                        <span className = "appInfo__tag">
                            {props.appinfo_tag1}
                        </span>
                        <span className = "appInfo__tag">
                            {props.appinfo_tag2}
                        </span>
                    </div>
                </div>
                <div className = "appInfo__descr">
                    <p className = "appInfo__text">
                        {props.appinfo_text1}
                    </p>
                    <p className = "appInfo__text">
                        {props.appinfo_text2}
                    </p>
                </div>
                <div className={"secondSlide__buttons "}>
                    {buttonsTemplate}
                </div>
            </div>
        </div>
    );
}
  
export default SecondSlide;


