import { useContext, useState, useEffect} from "react"
import { Context } from "../context/Context"
import JobsItem from "./JobsItem"
import './Jobs.sass'

function Jobs(props) {
    const {data, isRu} = useContext(Context)
    function fillJobsItem(el) {
        let obj = Object.values(el)
        let arr = []
        for(let key in obj) {
            let jobsData = obj[key]
            arr.push(jobsData)
        }
        let jobs = arr.map((jobsData, num) => {
            let last = arr.length - 1
            return(
                <JobsItem
                    last = {num === last}
                    place = {props.place}
                    key = {num}
                    jobNum = {num}
                    mainTitle = {jobsData.description_header_title}
                    subTitle = {jobsData.description_header_text}
                    titleDuties = {jobsData.description_list_title1}
                    titleEpxpectations = {jobsData.description_list_title2}
                    listItems1 = {jobsData.description_list_items1}
                    listItems2 = {jobsData.description_list_items2}
                />
            )})
        setJobsItemTemplate(jobs)
    }

    const [jobsItemTemplate, setJobsItemTemplate] = useState()
    useEffect(() => {
        fillJobsItem(data? isRu? data.fourthslide.fourthslide_jobs : data.fourthslide_eng.fourthslide_jobs : '')
    }, [isRu, data])

    return(
        <ul 
            className ={"jobs " + props.place +  " noSwipe"} >
            {jobsItemTemplate}
        </ul>
    )
}

export default Jobs