import './SendResult.sass'
import checkImg from '../../images/checkSixthSlide.svg'
import errImg from '../../images/sixthSlideErr.svg'
import { useContext } from 'react'
import { Context } from "../../context/Context"

function SendResult(props) {
    const sendAgaing= () => {
        handleIsSent(false)
        handleErr(false)
    }
    const {isSent, err, handleIsSent, handleErr} = useContext(Context)
    return(
        <>
            <div className={isSent && !err ? 'sent' : 'sent-hide'}>
                <img 
                    src={checkImg}
                    className="sent__image"
                    alt="sent"
                />
                <h3
                    className="sent__title"
                >
                    письмо отправлено
                </h3>
                <p
                    className="sent__text"
                >
                    {props.content? props.content.success : ''}
                    <span className="sent_email">
                        {" " + props.yourEmail}
                    </span>
                </p>
                <button
                    onClick={() => sendAgaing()}
                    className="sent__button"
                >
                    {props.content? props.content.otpravit_eshhe : ''}     
                </button>
            </div>

            <div className={!isSent && err? 'sent__err' : 'sent__err-hide'}>
                <img 
                    src={errImg}
                    className="sent__image"
                    alt="sent"
                />
                <h3
                    className="sent__title"
                >
                    Ошибка отправки
                </h3>
                <p
                    className="sent__text"
                >
                    {props.content? props.content.error : ''}
                </p>
                <button
                    onClick={() => sendAgaing()}
                    className="sent__button"
                >
                    {props.content? props.content.otpravit_eshhe : ''}       
                </button>
            </div>
        </>
    )
}

export default SendResult