import "./PopupOne.sass"
import closeCross from "../images/x.svg"
import { useRef, useContext, useEffect, useState } from "react"
import { Context } from "../context/Context";
import PopupJobs from "./PopupJobs";
import PopupDescription from "./PopupJobDescriprion";

function PopupOne(props) {
    const {openPopupTwo, closePopup, isRu, data} = useContext(Context)
    const rootEl = useRef(null)
    const handleClickOutside = (e) => {
        if (!rootEl.current.contains(e.target)) {
            closePopup()
        }
    }
    const [title, setTitle] = useState()
    const [button, setButton] = useState()
    useEffect(() => {
        let button = data?
                        isRu? data.fourthslide
                            : data.fourthslide_eng
                        : ''
        setButton(button)
        let title = data?
                        isRu? data.menu[2].title
                            : data.menu_eng[2].title
                        : ''
        setTitle(title)
    }, [data, isRu]) 

    return(
        <div 
            className={props.isOpen? "popup noSwipe" : "disabled"}
            onClick = {handleClickOutside}
        >
            <div 
                ref={rootEl}
                className="popup__container noSwipe">
                <div className="popup__jobs noSwipe">
                    <h3 className='jobs__header noSwipe'>
                        {title? title : 'Vacancy'}
                    </h3>
                    <PopupJobs />
                    <button 
                        onClick= {openPopupTwo}
                        className="jobs__button">
                            {button? button.fourthslide_jobs_button: 'send'}
                    </button>
                </div>
                <PopupDescription/>
                <div 
                    className="popup__close"
                    onClick = {closePopup}
                >
                    <img 
                        src={closeCross}
                        alt="close"> 
                    </img>
                </div>
            </div>
        </div>
    )
}

export default PopupOne