import { Context } from "../context/Context";
import {useEffect, useState, useContext } from "react"

function PopupJobDescriprion(params) {
    const {data, isRu, job} = useContext(Context)
    function fillDesctiption(el) {
        let obj = Object.values(el)
        let arr1 = []
        let arr2 = []
        let arrTitles = []
        let arrSubTitles = []
        for(let key in obj) {
            let jobsData = obj[key]
            arrTitles.push(jobsData.description_header_title)
            arrSubTitles.push(jobsData.description_header_text)
            arr1.push(jobsData.description_list_items1)
            arr2.push(jobsData.description_list_items2)
        }
        let jobs1 = arr1.map((jobsData, num) => {
            if (jobsData) {
                return jobsData.map((item, index) => {
                    return(
                        <li    
                            key={Date.now() + index}
                            className="description__item">
                            {item.text}
                        </li>
                    )
                })
            }

        })
        let jobs2 = arr2.map((jobsData, num) => {
            if (jobsData) {
                return jobsData.map((item, index) => {
                    return(
                        <li 
                            key={Date.now() + index}
                            className="description__item">
                            {item.text}
                        </li>
                    )
                })
            }
        })
        setDescriptionTitles(arrTitles)
        setDescriptionSubTitles(arrSubTitles)
        setJobsDescription1(jobs1[job])
        setJobsDescription2(jobs2[job])
    }   
    const [jobNum, setJobNum] = useState(0)
    const [jobsDescription1, setJobsDescription1] = useState()
    const [jobsDescription2, setJobsDescription2] = useState()
    const [descriptionTitles, setDescriptionTitles] = useState()
    const [descriptionSubTitles, setDescriptionSubTitles] = useState()
    const [stringOne, setStringOne] = useState('')
    const [stringTwo, setStringTwo] = useState('')
    useEffect(() => {
        fillDesctiption(data? isRu? data.fourthslide.fourthslide_jobs : data.fourthslide_eng.fourthslide_jobs : '')
        setJobNum(job)
        if (data && isRu) {
            setStringOne(data.fourthslide.textcontainer_responsibilities);
            setStringTwo(data.fourthslide.textcontainer_expect)
        }
        if (data && !isRu) {
            setStringOne(data.fourthslide_eng.textcontainer_responsibilities)
            setStringTwo(data.fourthslide_eng.textcontainer_expect)
        }
    }, [isRu, data, job])

    return(
        <div className="popup__description">
            <h3 className="description__header">
                {descriptionTitles? descriptionTitles[jobNum]: ''}
                <span>{descriptionSubTitles? descriptionSubTitles[jobNum]: ''}</span> 
            </h3>
            <ul className="description__list">
                {stringOne}
                {jobsDescription1}
            </ul>
            <ul className="description__list">
                {stringTwo}
                {jobsDescription2}
            </ul>
        </div>
    )
}

export default PopupJobDescriprion