import "./ThirdSlide.sass"
import Metaballs from "../Metaballs/Metaballs";
import { Context } from "../context/Context";
import { useContext, useState, useEffect } from "react";


function ThirdSlide() {
    const {index, data, isRu, isMobile} = useContext(Context)
    const [content, setContent] = useState()
    const [textTepmlate, setTextTemplate] = useState('')
    const [numbersTextTepmlate, setNumbersTextTemplate] = useState('')

    function fillText(el) {
        let text = el['textcontainer_text']
        if (text) {
            let textTemp = text.map((textData, num) => {
                return(
                    <p key={num} className="textContainer__text"> 
                        {textData.text}
                    </p>
                )
            })
            setTextTemplate(textTemp)
        }
        else setTextTemplate('ангийский текст')
    }
    function fillNumbersText(el) {
        let text = el['textcontainer_numbers']
        if (text) {
            let textTemp = text.map((textData, num) => {
                return(
                    <div
                        key = {num} 
                        className="numbers__item">
                            <div className="item__number">
                                <div>
                                    {textData.item_number}
                                </div>
                            </div>
                            <p className="item__text">
                                {textData.item_text}
                            </p>
                        </div>
                )
            })
            setNumbersTextTemplate(textTemp)
        }
        else setNumbersTextTemplate('английский текст')
    }
    useEffect(() => {
        let tempData = data?
                        isRu? data.thirdslide
                            : data.thirdslide_eng
                        : ''
        setContent(tempData)
        fillText(tempData)
        fillNumbersText(tempData)
    }, [data, isRu])

    return (
        <div  
            id="third"
            className="thirdSlide"
        >   
            {!isMobile?
                <Metaballs 
                    index = {index}
                    count = {2}
                /> : ''
            }
            <img
                className="thirdSlide__caslte" src={content? content.thirdslide_caslte: ''} alt="castleImage"/>
            <div className="thirdSlide__textContaiter">
                <h2 className = "textContainer__title">
                    {content? content.textcontainer_title : 'title'}
                </h2>
                {textTepmlate}
                <div className="textContainer__numbers">
                    {numbersTextTepmlate}
                </div>
            </div>
        </div>
    );
}
  
export default ThirdSlide;