import "./FifthSlide.sass"
import rocketBig from "../images/rocketBig.png"
import Metaballs from '../Metaballs/Metaballs'
import { useContext, useState, useEffect} from "react"
import { Context } from "../context/Context"

function FifthSlide(props) {
    const {index, data, isRu, scrollHandler, setInputValue, isMobile} = useContext(Context)
    const [content, setContent] = useState()
    const [textTepmlate, setTextTemplate] = useState('')

    function fillText(el) {
        let text = el['textcontainer_list']
        if (text) { 
            let textTemp = text.map((textData, num) => {
                return(
                    <li key={num} className="list__item">
                        <img 
                            className="item__image" 
                            src = {textData.item_image}
                            alt = 'item__image'
                        />
                        <p>
                            {textData.item_text}
                        </p>
                    </li>
                )
            })
            setTextTemplate(textTemp)
        }
        else setTextTemplate('ангийский текст')
    }

    const handleButton = () => {
        scrollHandler('sixth', 5)
        setInputValue('')
    }

    useEffect(() => {
        let tempData = data?
                        isRu? data.fifthslide
                            : data.fifthslide_eng
                        : ''
        setContent(tempData)
        fillText(tempData)
    }, [data, isRu])

    return(
        <div 
            id="fifth"
            className = "fifthSlide"
        >   
            {!isMobile? 
                <Metaballs
                    short
                    index = {index}
                    count = {4}
                /> : ''
            }
            <div className = "fifthSlide__textContainer">
                <p className="textContainer__text">
                    {content? content.textcontainer_text : 'text'}
                </p>
                <ul className="textContainer__list">
                   {textTepmlate}
                </ul>
                <button 
                    onClick={() => handleButton()}
                    className = "textContainer__button"
                >
                    {content? content.textcontainer_button : 'text'}
                </button>
            </div>
            <h2 
                className = "fifthSlide__title"
            >
                {content? content.fifthslide_title : 'title'}
            </h2>
            <img 
                className = "fifthSlide__rocketBig" 
                src={content? content.fifthslide_rocketbig.url : rocketBig} 
                alt={''}
            />
        </div>
    )
}

export default FifthSlide