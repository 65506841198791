import { useContext, useState, useEffect } from "react"
import { Context } from "../context/Context"
import JobsItem from '../ForuthSlide/JobsItem'
import '../ForuthSlide/Jobs.sass'

function PopupJobs() {
    const {data, isRu} = useContext(Context)
    function fillJobsItem(el) {
        let obj = Object.values(el)
        let arr = []
        for(let key in obj) {
            let jobsData = obj[key]
            arr.push(jobsData)
        }
        let jobs = arr.map((jobsData, num) => {
            let last = arr.length - 1
            return(
                <JobsItem
                    last = {num === last}
                    place = {'onPopup'}
                    key = {num}
                    jobNum = {num}
                    mainTitle = {jobsData.description_header_title}
                    subTitle = {jobsData.description_header_text}
                    titleDuties = {jobsData.description_list_title1}
                    titleEpxpectations = {jobsData.description_list_title2}
                    listItems1 = {jobsData.description_list_items1}
                    listItems2 = {jobsData.description_list_items2}
                />
            )})
        setJobsItemTemplate(jobs)
    }

    const [jobsItemTemplate, setJobsItemTemplate] = useState()
    useEffect(() => {
        fillJobsItem(data? isRu? data.fourthslide.fourthslide_jobs : data.fourthslide_eng.fourthslide_jobs : '')
    }, [isRu, data])
    return(
        <ul className="jobs onPopup noSwipe">
            {/* <li
                // onClick = {() => handleJob(1)}
                className = {job === 1?
                    "jobs__item chosen": "jobs__item"}
            >
                game designer
                <span> + </span>
            </li>
            <li 
                // onClick = {() => handleJob(2)}
                className = {job === 2?
                    "jobs__item chosen": "jobs__item"}
            >
                narrative designer
                <span> + </span>
            </li>
            <li 
                // onClick = {() => handleJob(3)}
                className = {job === 3?
                    "jobs__item chosen": "jobs__item"}
            >
                ux/ui artist
                <span> + </span>
            </li>
            <li 
                // onClick = {() => handleJob(4)}
                className = {job === 4?
                    "jobs__item chosen": "jobs__item"}
            >
                qa engineer
                <span> + </span>
            </li>
            <li 
                // onClick = {() => handleJob(5)}
                className = {job === 5?
                    "jobs__item chosen": "jobs__item"}
            >
                lead qa engineer
                <span> + </span>
            </li>
            <li 
                // onClick = {() => handleJob(6)}
                className = {job === 6?
                    "jobs__item chosen": "jobs__item"}
            >
                ux/ui engineer
                <span> + </span>
            </li> */}
            {jobsItemTemplate}
        </ul>
    )
}

export default PopupJobs