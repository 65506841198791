import './MetaballsStyles/Metaballs.sass'
import './MetaballsStyles/MetaballsRight.sass'
import './MetaballsStyles/MetaballsKeyFrames.sass'
import './MetaballsStyles/MetaballsColors.sass'
import { useContext } from 'react'
import { Context } from '../context/Context'

function Metaballs(props) {
  const {isSent, err, handleIsSent, handleErr} = useContext(Context)
  let activeSlide = props.index === props.count 
  const colors = ['ligth-blue', 'horisontalMode', 'green', 'orange', 'pink', 'purple', 'purple']
  const horisontalColors = 'horisontalMode'
  const sides = ['ball-left', 'ball-right', 'ball-left', 'ball-left', 'ball-right', 'ball-left']
  let className
  let wrapperClassName = props.short? 'wrapper short' : 'wrapper'
  if (props.isMobile) {
    className = 'ball-left ' + colors[props.count] + ' show'

    if(isSent) {
      className = 'ball-left ' + ' green' + ' show'
    }
  
    if(err) {
      className = 'ball-left ' + ' red' + ' show'
    }
  }
  else {
    if (props.count === 1) {
      className = 'ball-right ' + horisontalColors + (activeSlide? ' show': ' ')
    }
  
    else if(isSent) {
      className = sides[props.count] + ' green' + (activeSlide? ' show': '') 
    }
  
    else if(err) {
      className = sides[props.count] + ' red' + (activeSlide? ' show': '') 
    }
  
    else {
      className = sides[props.count] + ' ' + colors[props.count] + ' ' + (activeSlide? 'show': ' ') 
    }  
  }
  
  return(
    <>
      <div className ={wrapperClassName}>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
        <div className={ className }></div>
      </div>

      <svg>
        <defs>
          <filter id="filter">
            <feGaussianBlur in="SourceGraphic" stdDeviation="18" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 28 -10" result="filter" />
            <feComposite in="SourceGraphic" in2="filter" operator="atop" />
          </filter>
        </defs>
      </svg>
    </>
    
  )
}

export default Metaballs
