import React, { useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import 'swiper/swiper.scss';
import SwiperCore, {
  Mousewheel, Pagination, Navigation, Keyboard
} from 'swiper/core';
import './HorisontalSlider.sass'
import SecondSlide from "../SecondSlide/SecondSlide"
import Metaballs from "../Metaballs/Metaballs";
import { Context } from '../context/Context'
import appLogo from '../images/appLogo1.png'

SwiperCore.use([Mousewheel, Pagination, Navigation, Keyboard]);

function HorisontalSlider() {
    const {index, data, isRu, indexHorisontal, isHex, setIndexHorisontal, isMobile} = useContext(Context)
    function fillSlide(el) {
        let obj = Object.values(el)[0]
        let arr = []
        for(let key in obj) {
            let slideData = obj[key]
            arr.push(slideData)
        }
        let slides = arr.map((slideData, num) => {
            return(
                <SwiperSlide key = {num}>
                    <SecondSlide 
                        num = {num.toString()}
                        color = {slideData.secondslide_color? slideData.secondslide_color : ''}
                        // appinfo_logo = {slideData.secondslide_icon? slideData.secondslide_icon : ''}
                        appinfo_title = {slideData.appinfo_title? slideData.appinfo_title : ''}
                        appinfo_text1 = {slideData.appinfo_text[0]? slideData.appinfo_text[0].text : ''}
                        appinfo_text2 = {slideData.appinfo_text[1]? slideData.appinfo_text[1].text : ''}
                        appinfo_tag1 = {slideData.appinfo_tag[0]? slideData.appinfo_tag[0].text : ''}
                        appinfo_tag2 = {slideData.appinfo_tag[1]? slideData.appinfo_tag[1].text : ''}
                        secondslide_foto = {slideData.secondslide_foto? slideData.secondslide_foto : ''}
                        secondslide_foto_2 = {slideData.secondslide_foto_2? slideData.secondslide_foto_2 : ''}
                        buttons = {slideData.secondslide_buttons? slideData.secondslide_buttons : ''}
                    />
                </SwiperSlide>)
        })
        setSlideTemplate(slides)
        setPaginationImages(data)
    }

    const [content, setContent] = useState()
    const [title, setTitle] = useState()
    const [slideTemplate, setSlideTemplate] = useState()
    useEffect(() => {
        let tempData = data?
                        isRu? data.secondslides.secondslide[0]
                            : data.secondslides_eng.secondslide[0]
                        : ''
        setContent(tempData)
        fillSlide(data? isRu? data.secondslides : data.secondslides_eng : '')
        setTitle(data? isRu? data.secondslides.secondslide_title : data.secondslides_eng.secondslide_title : '')
    }, [data, isRu])

    const setPaginationImages = (data) => {
        if (data) {
            setTimeout(() => {
                let bullets =  document.querySelectorAll('.bullet-horisontal')
                bullets.forEach((el, index) => {
                    if (el.firstChild) {
                        el.removeChild(el.firstChild)
                    }
                    let applogo = document.createElement('img')
                    if (isHex(data.secondslides.secondslide[index].secondslide_color)) {
                        el.style.border =`2px solid ${data.secondslides.secondslide[index].secondslide_color}`
                    }
                    if (!isHex(data.secondslides.secondslide[index].secondslide_color)) {
                        el.style.border ='2px solid #98ADFF'
                    }
                    applogo.setAttribute('src', data.secondslides.secondslide[index].secondslide_icon? data.secondslides.secondslide[index].secondslide_icon : appLogo)
                    applogo.setAttribute('alt', 'appLogo')
                    el.appendChild(applogo)
                })
            } , 1000)
        }
    }

    return (
        <div 
            id='second'
            className='lala'>
            <h2 className="slider-horizontal__title">
                {content? title : 'title'}
            </h2>
            <Swiper 
                // cssMode
                navigation={true}
                direction={'horizontal'} 
                speed={800}
                noSwiping
                noSwipingClass = {'noSwipe-horisontal'}
                slidesPerView={1} 
                spaceBetween={300} 
                mousewheel={{
                    forceToAxis: true,
                    thresholdTime: 800,
                    thresholdDelta: 25
                }}
                keyboard={true}
                pagination = {{
                    "clickable" : true,
                    bulletClass: 'bullet-horisontal noSwipe-horisontal',
                    bulletActiveClass: 'bulletActive-horisontal',
                    horizontalClass: 'pagination-horisontal'
                }}
                className="slider-horizontal"
                onSlideChange={(swiper) => setIndexHorisontal(swiper.activeIndex)}
                >
                {!isMobile?
                    <Metaballs 
                        indexHorisontal = {indexHorisontal}
                        index = {index}
                        count = {1}
                        short 
                    /> : ''
                }
                {slideTemplate}
            </Swiper>
        </div>
    )
}

export default HorisontalSlider