import { useContext, useState, useEffect } from 'react';
import './Header.sass'
import mainLogo from '../images/newLogo.svg'
import mainLogoWhite from '../images/newLogoW.svg'
import discordLogo from '../images/discordLogo.svg'
import burger from '../images/burgerLogo.svg'
import burgerWhite from '../images/burgerLogoWhite.svg'
import { Context } from '../context/Context';
import MakeThemMove from '../HoverButton/HoverButton'

function Header(props) {
    const {hideHandler, data, isRu, index, setShakingForm, scrollHandler, discordUrl, setDiscordUrl, slideNum, setCoinNum, coinNum} = useContext(Context)
    const [content, setContent] = useState()
    const [contacts, setContacts] = useState()
    const [headerClass, setHeaderClass] = useState('header')

    const handleContacts = () => {
        if ( index ===5 ) {
            setShakingForm('shaking')
            setTimeout(() => setShakingForm(' '), 1000)
        }
        else scrollHandler('sixth', 5)
    }

    useEffect(() => {
        slideNum !== 0 && window.innerWidth < 923? 
            setHeaderClass('header gradient') :
            setHeaderClass('header')
    }, [slideNum])

    useEffect(() => {
        let tempData = data?
                        isRu? data.menu
                            : data.menu_eng
                        : ''
        setContent(tempData)
        let contactsData = data?
                        isRu? data.header_contacts
                            : data.header_contacts_eng
                        : ''
        setContacts(contactsData)
        let tempDiscord = data?
                        isRu? data.sixthslide.leftside_links[2].link
                            : data.sixthslide_eng.leftside_links? data.sixthslide_eng.leftside_links[2].link : ''
                        : ''
        setDiscordUrl(tempDiscord)
        const buttons = document.querySelector('.header__contacts').childNodes
        MakeThemMove(buttons)
        let docTilte = data? 
            isRu? 
                data.zagolovok_tajtla_rus? data.zagolovok_tajtla_rus : 'GAMEFIRST разработка и паблишинг игр для смартфонов и ПК'
                : 
                data.zagolovok_tajtla_angl? data.zagolovok_tajtla_angl : 'GAMEFIRST'
            :
            'GAMEFIRST разработка и паблишинг игр для смартфонов и ПК'
        document.title = docTilte
        console.log(data? setCoinNum(data.fourthslide.fourthslide_jobs.length) : '');
    }, [data, isRu])

    return (
        <header className={headerClass}>
            <img 
                src={headerClass.includes('gradient')? mainLogoWhite : mainLogo}
                className="header__logo" 
                alt="mainLogo"
                onClick={() => scrollHandler('first', 0)}
            />
            <nav >
                <ul className="header__nav">
                    <li 
                        onClick={() => scrollHandler('second', 1)}
                        className="nav__item"
                    >
                        {content? content[0].title : 'Проекты'}
                    </li>
                    <li 
                        onClick={() => scrollHandler('third', 2)}
                        className="nav__item">
                        {content? content[1].title : 'О компании'}
                    </li>
                    <li 
                        onClick={() => scrollHandler('fourth', 3)}
                        className="nav__item">
                        {content? content[2].title : 'Вакансии'}
                    </li>
                    <li 
                        onClick={() => scrollHandler('fifth', 4)}
                        className="nav__item">
                        {content? content[3].title : 'Паблишинг'}
                    </li>
                </ul>
            </nav>
            <div className="header__contacts">
                <button    
                    onClick={() => handleContacts()}
                    className="contacts__contacts"
                >
                    {contacts? contacts : 'Контакты'}
                </button>

                <button
                    className='contacts__discord '
                >
                    <a 
                        target="_blank"
                        rel="noreferrer"
                        href={discordUrl}>
                        <img src={discordLogo} alt='discordLogo'></img>
                    </a>
                </button>
            </div>
            <div className="header__burger">
                <img
                    onClick = {hideHandler}
                    src={headerClass.includes('gradient')? burgerWhite : burger}
                    alt={'burgerMenu'}
                    >
                </img>
                <span className="coin">
                    {coinNum}
                </span>
            </div>
            
        </header>
    );
}

export default Header;