import './Loader.sass'

function SendResult(props) {
    let className = (props.isVisible? 'loader' : ' ') + (props.onPopup? ' onPopup' : ' ')
    return(
        <div className={className}>
            <div className='loader__ball'></div>
            <div className='loader__ball'></div>
            <div className='loader__ball'></div>
            <div className='loader__ball'></div>
            <div className='loader__ball'></div>
        </div>
    )
}

export default SendResult